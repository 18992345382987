// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-newsletter-js": () => import("/opt/build/repo/src/templates/newsletter.js" /* webpackChunkName: "component---src-templates-newsletter-js" */),
  "component---src-templates-gallerytemplate-js": () => import("/opt/build/repo/src/templates/gallerytemplate.js" /* webpackChunkName: "component---src-templates-gallerytemplate-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-events-js": () => import("/opt/build/repo/src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-gallery-js": () => import("/opt/build/repo/src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("/opt/build/repo/src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */)
}

